import TypeRequestedFlow from "../../static-data/TypeRequestedFlow";
import axios from "../http-client/axios";
import requestBuilder from "../request-builder/request-builder";
import { SCORPIONS_URL } from "../urls/base-urls";
import mapperToCharterRequest from "./charter-mapper";
import ServiceRequestType from "../../static-data/ServiceRequestType";

export async function addCharter(formData, attachments) {
  const resp = await axios.post(
    `${SCORPIONS_URL}/update-information-data`,
    requestBuilder(
      mapperToCharterRequest(formData),
      TypeRequestedFlow.save,
      attachments,
      ServiceRequestType.DOCTOR_TAXES_AND_PERMIT_SEND
    )
  );
  return resp.data;
}
