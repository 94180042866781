<template>
  <div>
    <AlterFormLayout
      modalId="alvara-alter-modal"
      formTitle="Enviar alvará"
      cancelButtonText="Cancelar"
      submitButtonText="Enviar"
      :onSubmitEvent="onSubmit"
      :disableEvent="this.$store.getters.isAdmin"
    >
      <template #content>
        <InformationAlvaraForm newRegister="true" />
      </template>
    </AlterFormLayout>
    <success-modal
      ref="success-modal"
      description="Seu alvará de profissional não estabelecido foi enviado."
      buttonText="Ok, entendi!"
      subdescription="O documento será analisado e atualizado após a validação."
      :protocol="this.protocol"
    />
  </div>
</template>

<script>
import AlterFormLayout from "../../../../../layouts/AlterFormLayout.vue";
import SuccessModal from "../../../../../components/base-modals/SuccessModal.vue";
import InformationAlvaraForm from "./InformationAlvaraForm.vue";
import { addCharter } from "../../../../../services/charter/charterServise";

export default {
  components: {
    AlterFormLayout,
    SuccessModal,
    InformationAlvaraForm,
  },
  data() {
    return {
      protocol: "",
    };
  },
  methods: {
    async onSubmit(formData) {
      this.protocol = await addCharter(formData, formData.files);
      const modal = this.$refs["success-modal"].$children[0];
      modal.show();
    },
  },
};
</script>

<style lang="scss" scoped>
div.form-row {
  padding: 0.5rem;
  border-bottom: 1px solid $gray-300;
  &:last-child {
    border-bottom: none;
  }
}

input.form-control {
  text-align: right;
  border: 1px solid transparent;
}

select.custom-select {
  text-align: right;
  border: 1px solid transparent;
}
</style>
