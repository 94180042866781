<template>
  <b-container fluid class="general-information">
    <b-row class="justify-content-center mb-4">
      <p class="p4">
        Confira seus dados e, caso haja divergências, solicite alteração
        cadastral a qualquer momento.
      </p>
    </b-row>
    <b-row>
      <b-card class="flex-grow-1" border-variant="light">
        <b-form-row>
          <b-col>
            <p class="p3">
              ANO CORRESPONDENTE DO ALVARÁ
            </p>
          </b-col>
          <b-col class="text-right">
            <h5>{{ new Date().getFullYear() }}</h5>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <p class="p2">
              Envie anualmente o seu alvará de profissional não estabelecido,
              documento que autoriza a sua atuação como profissional autônomo.
            </p>
            <p class="p2">
              É importante manter seu alvará sempre atualizado, pois através
              dele é realizada a comprovação do seu registro e regulamentação
              junto ao municipio onde você atua.
            </p>
          </b-col>
        </b-form-row>
      </b-card>
    </b-row>

    <b-row class="my-4">
      <b-button
        class="my-3 mr-3"
        variant="primary"
        v-b-modal.alvara-alter-modal
      >
        Enviar alvará
      </b-button>
    </b-row>

    <information-alvara-alter-modal />

    <SuccessModal
      description="Solicitação de alteração enviada!"
      buttonText="Ok, entendi!"
    />
  </b-container>
</template>

<script>
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import InformationAlvaraAlterModal from "../forms/alvara-information/InformationAlvaraAlterModal.vue";

export default {
  components: {
    SuccessModal,
    InformationAlvaraAlterModal,
  },
};
</script>

<style lang="scss" scoped>
div.form-row {
  padding: 0.5rem;
  border-bottom: 1px solid $gray-300;
  &:last-child {
    border-bottom: none;
  }
}

input.form-control {
  text-align: right;
  border: 1px solid transparent;
}

select.custom-select {
  text-align: right;
  border: 1px solid transparent;
}
</style>
