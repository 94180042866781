<template>
  <b-card border-variant="light" class="custom-card">
    <b-form-row>
      <b-col>
        ANO CORRESPONDENTE DO ALVARÁ
      </b-col>

      <h5>{{ CurrentDateAno() }}</h5>
    </b-form-row>
    <b-form-row>
      <b-col>
        <UploadFile
          @updateFile="eventToUpdateFile"
          :multiple="true"
          :description="'Anexar alvará de profissional não estabelecido'"
          :msgErro="'É necessário inserir um anexo com o alvará.'"
          :state="validateFieldFile('files')"
          :required="true"
          v-model.lazy.trim="form.files"
        />
      </b-col>
    </b-form-row>
  </b-card>
</template>

<script>
import { inject } from "@vue/composition-api";
import UploadFile from "../../../../../components/upload-file/UploadFile.vue";

const DefaultForm = {
  year: null,
  files: null,
};

export default {
  props: ["loadedFormDataOptions", "newRegister"],
  components: { UploadFile },
  setup() {
    const setForm = inject("setForm");
    const formData = inject("formData");
    const setSubmitHandler = inject("setSubmitHandler");
    return { formData, setForm, setSubmitHandler };
  },
  mounted() {
    this.initForm();
  },
  data() {
    return {
      formSubmited: false,
      form: {
        ...DefaultForm,
      },
      validations: {},
    };
  },
  methods: {
    initForm() {
      this.setSubmitHandler(this.onSubmit);

      if (!this.$props.newRegister) {
        const loadedFormDataOptions = this.$props.loadedFormDataOptions;
        this.selectOptions = this.buildSelectOptions(loadedFormDataOptions);
      }
    },
    eventToUpdateFile(files) {
      this.form.year = this.CurrentDateAno();
      this.form.files = files;
    },
    onSubmit() {
      this.formSubmited = true;
      this.setForm(this.form);
      if (this.requiredFieldsFilled()) return true;
    },
    validateFieldFile(name) {
      let value = this.form[name];
      const validation = !!value && value.length > 0;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;
      return validation;
    },
    requiredFieldsFilled() {
      return Object.values(this.validations).every(
        (fieldValue) => fieldValue == true
      );
    },
    CurrentDateAno() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>
div.form-row {
  padding: 0.5rem;
  border-bottom: 1px solid $gray-300;
  &:last-child {
    border-bottom: none;
  }
}

input.form-control {
  text-align: right;
  border: 1px solid transparent;
}

select.custom-select {
  text-align: right;
  border: 1px solid transparent;
}
</style>
